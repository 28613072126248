body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.successMessage {
  text-align: center;
  padding-bottom: 50px;
  color: #144b52;
  font-weight: bold;
}

br {
  display: block;
  margin: 200px 50;
}

/* dropdown */

.dropdown {
  position: relative;
  display: inline-block;
  text-decoration: none !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  border-radius: 5px;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  text-decoration: none !important;
}

.dropdown:hover .dropdown-content {
  display: block;
  text-decoration: none !important;
}

/* alternating content/images */

* {
  box-sizing: border-box;
}

body {
  background-color: #eef1ee;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
}

.parent {
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 4vw;
  margin-left: 15vw;
  margin-right: 15vw;
  box-shadow: 4px 5px 5px 2px #aaaaaa;
  border-radius: 5px;
  background-color: white;
  padding: 2vw;
  padding: 20px;
}
.parent:nth-child(odd) {
  flex-direction: row-reverse;
}

.child {
  /* background-color: blue; */
  /* display: flex; */
  flex: 1;
  margin: 20px;
  /* flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2vw;
  margin-left: 10vw;
  padding: 2vw; */
  /* background-color: green; */
}

.child-image {
  flex: 1;
  flex-direction: column;
  /* text-align: center; */
  align-items: center;
  text-align: center;
  /* margin-top: 2vw; */
  /* margin-top: 5vw; */
  /* margin-left: 10vw; */
  /* background-color: blue; */
  /* padding: 2vw; */
}
.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.parent:nth-child(odd) .child-image {
}

.content-image {
  border-radius: 5%;
  align-items: center;
  vertical-align: middle;
  width: 80%;
}

@media (max-width: 800px) {
  .parent {
    flex-direction: column;
    margin-left: 2vw;
    margin-right: 2vw;
    margin-top: 6vw;
    margin-bottom: 5vw;
  }
  .parent:nth-child(odd) {
    flex-direction: column;
  }
  .child {
    margin-left: 0vw;
  }
  .child-image {
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0vw;
    margin-left: 0vw;
    padding: 0vw;
    text-align: center;
  }
  .content-image {
    text-align: center;
  }
}

.content-left {
  margin-left: 4vw;
  margin-top: 3vw;
  width: 50%;
}
.content-right {
  float: right;
  margin-top: 3vw;
  width: 50%;
}

/* end */

.content-container-left {
  margin-left: 4vw;
  /* margin-top: 2vw; */
  width: 75vw;
}

.content-container-right {
  /* uncomment below for right align */
  margin-left: 50%;
  /* margin-left: 4vw; */
  margin-top: 2vw;
  width: 35vw;
}

/* checkbox */
/* The container */
.checkbox_container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 2em;
  font-family: "Montserrat", Arial, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  padding-top: 0.2em;
}

/* Hide the browser's default checkbox */
.checkbox_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox_container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox_container input:checked ~ .checkmark {
  background-color: #144b52;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox_container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox_container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* form css */
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);

.centered {
  display: flex;
  justify-content: center;
}

.pageTitle {
  text-align: center;
  margin: 2vw;
}

html {
  font-family: "Montserrat", Arial, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
p {
  font-family: "Montserrat", Arial, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.6;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
input,
select,
textarea {
  color: #5a5a5a;
  font: inherit;
  margin: 0;
}

input {
  line-height: normal;
}

textarea {
  overflow: auto;
}

#container {
  /* border: solid 3px #144b52; */
  max-width: 768px;
  margin: 60px auto;
  position: relative;
  box-shadow: 2px 2px 5px 5px grey;
  border-radius: 2%;
  background-color: white;
}

form {
  padding: 37.5px;
  margin: 10px 0;
}

h1 {
  color: #474544;
  font-size: 32px;
  font-weight: 700;
}

.email {
  float: right;
  width: 45%;
}

input[type="text"],
[type="email"],
select,
textarea {
  background: none;
  border: none;
  border-bottom: solid 2px #144b52;
  color: #474544;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0em 0 1.875em 0;
  padding: 0 0 0.875em 0;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

input[type="text"]:focus,
[type="email"]:focus,
textarea:focus {
  outline: none;
  padding: 0 0 0.875em 0;
}

.message {
  float: none;
}

.name {
  float: left;
  width: 45%;
}

select {
  background: url("https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-arrow-down-32.png")
    no-repeat right;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

textarea {
  line-height: 150%;
  height: 150px;
  resize: none;
  width: 100%;
}

::-webkit-input-placeholder {
  color: #474544;
}

:-moz-placeholder {
  color: #474544;
  opacity: 1;
}

::-moz-placeholder {
  color: #474544;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #474544;
}

/* end form css */

.button {
  border: none;
  color: white;
  padding: 8px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 0px;
  /* margin-top: 1.3vw; */
  transition-duration: 0.4s;
  cursor: pointer;
}

.learn_more_button {
  background-color: white;
  color: #144b52;
  border: 2px solid #144b52;
}

.learn_more_button:hover {
  background-color: #144b52;
  color: white;
}

.buttonWrapper {
  text-align: left;
}
@media screen and (max-width: 800px) {
  .buttonWrapper {
    text-align: center;
  }
}

html {
  scroll-behavior: smooth;
}
:target:before {
  content: "";
  display: block;
  height: 100px;
  margin: -100px 0 0;
}

h1 {
  color: #144b52;
  font-family: "Montserrat", Arial, sans-serif;
  margin-bottom: 1.8vw;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
h2 {
  color: #144b52;
  font-family: "Montserrat", Arial, sans-serif;
  margin-bottom: 1.8vw;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
.anchor {
  width: 50px;
  border: 4px solid #f19122;
  float: left;
}

.banner-image {
  width: 100%;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 100;
}
.sticky-wrapper {
  position: relative;
  height: 3rem;
}

.sticky .sticky-inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.header-logo {
  float: left;
  width: 210px;
  background-color: white;
}

@media (max-width: 1400px) {
  .header-logo {
    width: 120px;
  }
}

.nav-button {
  display: inline-block;
  position: relative;
  padding: 30px;
}
.navbar-container {
  float: none;
  text-align: center;
  padding-right: 100px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 100px;
  background-color: #144b52;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  float: none;
  text-align: center;
  justify-content: center;
  color: black;
}

.logo {
  float: left;
  margin-right: 50px;
}

.header {
  overflow: hidden;
  box-shadow: 10px 10px 5px grey;
}

.header a {
  font-family: "Roboto";
  color: white;
  font-weight: bold;
  text-decoration: none;
  font-size: 20px;
}

.header a.header-logo {
  color: white;
  font-size: 25px;
  font-weight: bold;
  padding: 30px;
}

.header a:hover {
  color: #f19122;
}

.header header-right {
  color: #144b52;
}

/* MOBILE */
@media screen and (max-width: 650px) {
  .content-container-right {
    margin-left: 4vw;
    margin-top: 2vw;
    width: 35vw;
  }
  .nav-button {
    display: inline-block;
    position: relative;
    padding: 5px;
  }
  .header a {
    font-family: "Roboto";
    color: white;
    font-weight: bold;
    text-decoration: none;
    font-size: 18px;
  }
  .nav-button {
    display: inline-block;
    position: relative;
    padding: 0px;
  }
  p {
    font-size: 14px;
  }
  h1 {
    font-size: 25px;
  }
}
@media screen and (max-width: 768px) {
  #container {
    margin: 20px auto;
    width: 95%;
  }
}

@media screen and (max-width: 480px) {
  p {
    font-size: 14px;
  }
  h1 {
    font-size: 26px;
  }

  #form_button {
    padding: 15px 25px;
  }
}

@media screen and (max-width: 420px) {
  h1 {
    font-size: 18px;
  }

  .icon {
    height: 35px;
    width: 35px;
  }

  input[type="text"],
  [type="email"],
  select,
  textarea {
    font-size: 0.875em;
  }
}
